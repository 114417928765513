import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from './services/events.service';
import { PreferencesService } from './services/preferences.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  username: string = 'Gast';

  public appPages = [
    { title: 'Stöbern', url: '/home', icon: '' },
    { title: 'Suche', url: '/search/blank', icon: '' },
    //{ title: 'Hilfe', func: 'chatbot', icon: '', msg: '' },
    { title: 'Hilfe', url: '/help', icon: '' },
    { title: 'Kontakt', url: '/contact', icon: '' },
    { title: 'Impressum', url: '/impressum', icon: '' },
    { title: '', url: '', icon: '' },
    { title: 'Anmelden', url: '/login', icon: 'log-in' }
  ];

  constructor(
    private translate: TranslateService,
    private events: EventsService,
    private preferences: PreferencesService,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();
  }

  async clickHandler(type: string, msg: string = '') {
    console.log('app.components.ts, clickHandler, type=', type);
    if (type === 'alert') {
      const alert = await this.alertCtrl.create({
        header: '',
        subHeader: '',
        message: msg,
        buttons: ['OK']
      });
      await alert.present();
      const result = await alert.onDidDismiss();
    }
    else {
      this.events.publishSomeData({
        page: null,
        step: 0,
        message: type
      });
    }
  }

  async initializeApp() {
    this.translate.setDefaultLang('de');

    const token: any = await this.preferences.getString('my-token');
    console.log('token=', token);
    const user: any = await this.preferences.getObject('me');
    console.log('user=', user);

    //this.preferences.setString('willbot', 'true');
    //this.preferences.setString('tempbot', 'false');

    if (token && user) {
      this.username = user['displayname'];  //user['email'];
      this.appPages = [
        { title: 'Startseite', url: '/home', icon: '' },
        { title: 'Suche', url: '/search/blank', icon: '' },
        { title: 'Mein Profil', url: '/profile', icon: '' },
        //{ title: 'Hilfe', func: 'chatbot', icon: '' },
        { title: 'Hilfe', url: '/help', icon: '' },
        { title: 'Mein Lernpfad', url: '/mylearningpath', icon: '' },
        { title: 'Meine Favoriten', url: '/myfavorites', icon: '' },
        { title: 'Meine Suchen', url: '/mysearches', icon: '' },
        { title: 'Kontakt', url: '/contact', icon: '' },
        { title: 'Impressum', url: '/impressum', icon: '' },
        { title: '', url: '', icon: '' },
        { title: 'Abmelden', url: '/logout', icon: 'log-out' }
      ];
    }

    this.events.getObservable().subscribe((data) => {

      console.log('app.components.ts, getObservable, data received:', data);

      if (data.action === 'login') {
        this.username = data.username;
        this.appPages = [
          { title: 'Stöbern', url: '/home', icon: '' },
          { title: 'Suche', url: '/search/blank', icon: '' },
          { title: 'Mein Profil', url: '/profile', icon: '' },
          //{ title: 'Hilfe', func: 'chatbot', icon: '' },
          { title: 'Hilfe', url: '/help', icon: '' },
          { title: 'Mein Lernpfad', url: '/mylearningpath', icon: '' },
          { title: 'Meine Favoriten', url: '/myfavorites', icon: '' },
          { title: 'Meine Suchen', url: '/mysearches', icon: '' },
          { title: 'Kontakt', url: '/contact', icon: '' },
          { title: 'Impressum', url: '/impressum', icon: '' },
          { title: '', url: '', icon: '' },
          { title: 'Abmelden', url: '/logout', icon: 'log-out' }
        ];
      } else if (data.action === 'logout') {
        this.username = '';
        this.appPages = [
          { title: 'Stöbern', url: '/home', icon: '' },
          { title: 'Suche', url: '/search/blank', icon: '' },
          //{ title: 'Hilfe', func: 'chatbot', icon: '' },
          { title: 'Hilfe', url: '/help', icon: '' },
          { title: 'Kontakt', url: '/contact', icon: '' },
          { title: 'Impressum', url: '/impressum', icon: '' },
          { title: '', url: '', icon: '' },
          { title: 'Anmelden', url: '/login', icon: 'log-in' }
        ];
      }
    });
  }
}
